.form-label-group {
  position: relative;
  margin-bottom: 1rem;
}

.form-label-group > select,
.form-label-group > input,
.form-label-group > label {
  padding: 0.75rem 0.75rem;
}

.form-label-group > label {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 100%;
  margin-bottom: 0; /* Override default `<label>` margin */
  line-height: 1.5;
  color: $gray-900;
  border: 1px solid transparent;
  border-radius: .25rem;
  transition: all .1s ease-in-out;
}

.form-label-group select,
.form-label-group input:not(:placeholder-shown) {
  padding-top: calc(0.75rem + 0.75rem * (2 / 3));
  padding-bottom: calc(0.75rem / 3);
}

.form-label-group select ~ label,
.form-label-group input:not(:placeholder-shown) ~ label {
  padding-top: calc(0.75rem / 3);
  padding-bottom: calc(0.75rem/ 3);
  font-size: 12px;
  color: $gray-900;
}

.custom-select {
  color: $gray-900;
  background-color: $gray-300;
  border-color: $gray-500;
  height: inherit;
  padding-right: 40px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.form-control {
  color: $gray-900;
  background-color: $gray-300;
  border-color: $gray-500;
  &::-webkit-contacts-auto-fill-button {
    visibility: hidden !important;
  }
}

.lookup {
  .form-label-group {
    border: 1px $gray-500 solid;
    background-color: $gray-200;
  }
}

.radio-image {
  img {
    height: 75px;
  }
}
