.nav {
  &.nav-tabs {
    background-color: $gray-300;
    .nav-link {
      cursor: pointer;
      border-top: 0 none;
      color: $gray-700;
      &:hover {
        color: $primary;
        border: 1px $gray-300 solid;
        border-top: 0 none;
      }
      &.active {
        border: $gray-500 1px solid;
        border-bottom: $white 1px solid;
        background-color: $white;
        color: $primary;
        &:hover {
          border: $gray-500 1px solid;
          border-bottom: $white 1px solid;
        }
      }
    }
  }
}
