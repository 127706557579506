@import "../../node_modules/bootstrap/scss/functions";
@import "variables";
@import "table";
@import "card";
@import "tab";
@import "form";

.app {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  min-width: 680px !important;
  &.header {
    padding-top: 55px
  }
}

header {
  flex-direction: row;
  height: 55px;
  padding: 0;
  margin: 0;
  border-bottom: 1px $gray-500 solid;
  .navbar {
    background-color: $white !important;
    border-bottom: 1px $gray-500 solid;
    .navbar-brand {
      img {
        height: $navbar-brand-font-size;
      }
      b {
        color: $redland;
      }
    }
    .navbar-links {
      a {
        &:hover {
          text-decoration: none;
        }
        &.active {
          font-weight: bold;
          &:hover {
            color: $primary;
          }
        }
      }
    }
    .navbar-nav {
      .nav-link {
        color: $gray-700;
        &.active {
          color: $white;
          font-weight: bold;
          background-color: rgba($primary,0.9);
          &:hover {
            background-color: rgba($primary,0.9);
          }
        }
        &:hover {
          background-color: $gray-200;
        }
      }
    }
  }
}

footer {
  width: 100%;
}

body {
  .container.header {
    padding-top: 55px;
  }
}

aside {
  width: 200px;
  div {
    left: 0;
    top: 55px;
    width: 200px;
  }
  .sidebar {
    z-index: $zindex-sticky;
    height: 100vh;
    background-color: $gray-100;
    border-right: 1px $gray-500 solid;

    .nav-item {
      .nav-link {
        color: $gray-700;
        &.active {
          background-color: rgba($primary,0.9);
          color: $white;
          font-weight: bold;
          &:hover {
            background-color: rgba($primary,0.9);
          }
        }
        &:hover {
          background-color: $gray-200;
        }
      }
    }
  }
}
