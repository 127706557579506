.card {
  &.with-tabs {
    border-top: 0 none;
  }

  .card-header {
    border:7px $white solid;
    padding: 0.5rem 0.5rem 0.5rem 1.25rem;
    .text-right {
      .btn {
        border: 1px $white solid;
      }
    }
  }

  .btn {
    i {
      border-color: $white;
    }
  }
}
